import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';

import PropTypes from 'prop-types';
import JSZip from 'jszip';
import { useTheme } from '@mui/material/styles';
import {saveAs} from "file-saver";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid
} from '@mui/material';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { collection, getDocs, getFirestore, addDoc, onSnapshot, updateDoc, doc } from "firebase/firestore";
import { getDatabase, set, serverTimestamp, runTransaction, ref as refDB, child, get, onValue, update } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useReactToPrint } from 'react-to-print';
import QRCode from "react-qr-code";
import QrCodeIcon from '@mui/icons-material/QrCode';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/device';

import { UserAuth } from '../context/AuthContext'

const firebaseConfig = {
  apiKey: "AIzaSyDgJh6jQcUPOI7oc8Tmv25JhtN_8h9rSic",
  authDomain: "ureshii-desu.firebaseapp.com",
  databaseURL: "https://ureshii-desu-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ureshii-desu",
  storageBucket: "ureshii-desu.appspot.com",
  messagingSenderId: "1085617450559",
  appId: "1:1085617450559:web:180370ff8286f97b31d28a",
  measurementId: "G-FM7XL7RD2V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getDatabase(app);

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'created', label: 'Created', alignRight: false },
  { id: 'file_fullPath', label: 'Image', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PhotoPage() {


  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('created');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [row, setRow] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);

  const promiseResolveRef = useRef(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);
  const imageRef = useRef(null);


  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleClose2 = () => {
    setDialogOpen2(false);
  };

  useEffect(() => {

      const photoRef = refDB(db, 'photo');
      onValue(photoRef, (snapshot) => {
        if(snapshot.exists()){
          const photos = [];

          snapshot.forEach((doc) => {
            photos.push(doc.val());
          });

          setRow(photos)
          setLoading(false)
        }
      });


      if (isPrinting && promiseResolveRef.current) {
        // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
        promiseResolveRef.current();
      }
    
  }, [isPrinting])

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
        size: 1200px 1800px;
        margin: 0;
      }
    }`,
    
    content: () => printRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
    }
  });

  const handleOpenMenu = (event, row) => {
    setSelectedItem(row)
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = row.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const generateZipFile = (photosToDownload) => {

    const jszip = new JSZip();
  
    photosToDownload.forEach((val, i)=> {
      const photoName = photosToDownload[i].file_fullPath.split('/')[1];
      const photoImageURL = photosToDownload[i].file_url;
  
      jszip.file(
        `${photoName}.jpg`, 
        downloadUrlAsPromise(photoImageURL) 
      );
      
      console.log(i)
      if (i === photosToDownload.length - 1) {
        
        jszip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, "MyZipFile.zip");
        });
      }
    })
  }
  
  const downloadUrlAsPromise = (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer";
      xhr.onreadystatechange = function(evt) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error(`Error for ${url}:${xhr.status}`));
          }
        }
      };
      xhr.send();
      
    });
  }

  const onDownloadFile = () => {
    generateZipFile(row)
    
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - row.length) : 0;

  const filteredUsers = applySortFilter(row, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  if(loading){
    return (
      
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title> Photo | Ureshii Desu Services </title>
      </Helmet>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Preview Photo"}
        </DialogTitle>
        <DialogContent>
          <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
           <img width={"90%"} src={selectedItem? selectedItem.file_url : ""} alt={selectedItem? selectedItem.file_fullPath : ""} />
          </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"SoftCopy"}
        </DialogTitle>
        <DialogContent>
          <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 300, height: 300}}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={selectedItem? `photobooth.ureshii-desu.com/order-number/softcopy/${selectedItem.id}` : ""}
            viewBox={`0 0 256 256`}
            />
          </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Close</Button>
        </DialogActions>
      </Dialog>


      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Photo
          </Typography>
         
          
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName}  onDownloadFile={onDownloadFile}/>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={row.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    let pending = "";

                    if(row.status === "pending_print"){
                      pending = "Pending for printing"
                    }
                    else{
                      pending = "Printed"
                    }

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{new Intl.DateTimeFormat('en-US', {
                    year: 'numeric', day: '2-digit', month: 'long', hour: '2-digit', minute: '2-digit', second: '2-digit'
                  }).format(row.created).toString()}</TableCell>

                        <TableCell align="left">{<img height={100} src={row.file_url} alt={row.file_fullPath} />}</TableCell>

                        <TableCell align="left">{pending}</TableCell>

                        <TableCell align="left">{row.quantity ? row.quantity: "none"}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e)=> handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            component="div"
            count={row.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Container>

                          <Grid style={{ position: "absolute", marginTop: -6000, marginLeft: -6000 }}>
                            <Grid ref={printRef} style={{ width: 1180, 
                            height: 1780, position: "absolute"}}>
                              <Grid style={{  
                                transform: selectedItem && selectedItem.orientation === "landscape" ?"rotate(90deg)" : "rotate(0deg)", 
                                marginLeft: selectedItem && selectedItem.orientation === "landscape" ? -290 : 0, 
                                marginTop: selectedItem && selectedItem.orientation === "landscape" ? 310 : 0,
                                width: selectedItem && selectedItem.orientation === "landscape" ? 1780 : 1180, 
                                height: selectedItem && selectedItem.orientation === "landscape" ? 1180 : 1780 }}>
                                  <img ref={imageRef} style={{ marginLeft: selectedItem && selectedItem.orientation === "landscape" ? 0 : 10,
                                  marginTop: selectedItem && selectedItem.orientation === "landscape" ? 0 : 10,
                                  }} 
                                  width={selectedItem && selectedItem.orientation === "landscape" ? 1780 : 1180} 
                                  height={selectedItem && selectedItem.orientation === "landscape" ? 1180 : 1780} src={""} alt=""/>
                              </Grid>
                            </Grid>
                          </Grid>
      
     

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

          <MenuItem onClick={()=> {
            
            selectedItem.print = "true";
              
              set(refDB(db, 'print'), {
                selectedItem
              }).then(()=>{
                update(refDB(db, `/photo/${selectedItem.id}`), {
                  status: "printed"
                })
              })

              setOpen(null)
            }}>
            <Iconify icon={'eva:printer-outline'} sx={{ mr: 2 }} />
            Print M
          </MenuItem>

          <MenuItem onClick={()=> {
              if(!isPrinting){

                imageRef.current.src = selectedItem.file_url;
                imageRef.current.onload = () => {
                  handlePrint();

                  update(refDB(db, `/photo/${selectedItem.id}`), {
                    status: "printed"
                  })
                };
                setOpen(null)
              }
              
            }}>
            <Iconify icon={'eva:printer-outline'} sx={{ mr: 2 }} />
            Print
          </MenuItem>

        <MenuItem onClick={()=> {
          setDialogOpen(true)
          setOpen(null)
          }}>
          <Iconify icon={'eva:eye-outline'} sx={{ mr: 2 }} />
          Preview
        </MenuItem>

        <MenuItem onClick={()=> {
          setDialogOpen2(true)
          setOpen(null)
          }}>
          <QrCodeIcon  style={{ marginRight: 12}} />
          Softcopy
        </MenuItem>

        <MenuItem onClick={()=> {
          const updateRef = doc(db, "photo", selectedItem.id);
          updateDoc(updateRef, {
            softcopy: true
          });

          setOpen(null)
          }}  >
          Active SoftCopy
        </MenuItem>

        <MenuItem onClick={()=> {
          const updateRef = doc(db, "photo", selectedItem.id);
          updateDoc(updateRef, {
            softcopy: false
          });

          setOpen(null)
          }}  >
          Inactive SoftCopy
        </MenuItem>

        <MenuItem onClick={()=> {
          saveAs(selectedItem.file_url, selectedItem.file_fullPath.split('/')[1]);
          setOpen(null)
          }}  >
          Download
        </MenuItem>
      </Popover>
    </>
  );
}
