import { Navigate, useRoutes } from 'react-router-dom';

import { Grid } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import Frontend from './layouts/frontend';
//

import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';

import PhotoPage from './pages/PhotoPage';
import UserPage from './pages/UserPage';
import NewUserPage from './pages/NewUserPage';
import LoginPage from './pages/LoginPage';

import Design1 from './pages/Design1';
import Design2 from './pages/Design2';
import Design3 from './pages/Design3';
import Design4 from './pages/Design4';
import OrderNumberPage from './pages/OrderNumberPage';
import SoftCopyPage from './pages/SoftCopyPage';

import { UserAuth } from './context/AuthContext'
// ----------------------------------------------------------------------

export default function Router() {

  const { user } = UserAuth()

  const routes = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'design1', element: <Design1 />},
        { path: 'design2', element: <Design2 />},
        { path: 'design3', element: <Design3 />},
        { path: 'design4', element: <Design4 />},
        { path: 'order-number/:id', element: <OrderNumberPage />},
        { path: 'order-number/softcopy/:id', element: <SoftCopyPage />},
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <Navigate to="/login" replace />,
    },
    {
      path: '/dashboard/*',
      element: <Navigate to="/login" replace />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const routesAdmin = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'design1', element: <Design1 />},
        { path: 'design2', element: <Design2 />},
        { path: 'design3', element: <Design3 />},
        { path: 'design4', element: <Design4 />},
        { path: 'order-number/:id', element: <OrderNumberPage />},
        { path: 'order-number/softcopy/:id', element: <SoftCopyPage />},
        
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/photo" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/photo" />, index: true },
        { path: 'photo', element: <PhotoPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'new_user', element: <NewUserPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  if(user === "none"){
    return (
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  if(user)
  {
    return routesAdmin
  }

  return routes;
}
