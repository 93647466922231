import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Routes, Route, useParams } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function OrderNumberPage() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  const { id } = useParams();

  console.log(id)

  return (
    <>
      <Helmet>
        <title> Thank you | Ureshii-Desu </title>
      </Helmet>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }} >


        <Grid style={{ width: "100%", height: "70vh", marginTop: 30, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
          {/* <Typography align='center' style={{ fontWeight: 700, fontSize: 30, marginTop: 50 }}>
          SIB AWARD NIGHT
  </Typography> */}

            <Typography align='center' style={{ fontWeight: "bold", marginTop: 50, color: "#db1d41"}}  variant="h2">
            Thank you for your support
            </Typography>
            <Typography align='center' style={{ marginTop: 10, fontWeight: "bold", color: "#db1d41"}}  variant="h2">
             谢谢您的支持
            </Typography>

            <Button variant='contained' href={`softcopy/${id}`} disableElevation style={{ backgroundColor: "#db1d41", marginTop: 10 }}>Softcopy</Button>

            <Typography style={{ fontWeight: "bold", marginTop: 30, color: "#9E9E9E" }}  variant="h5">
              Your beautiful photo
            </Typography>
            <Typography style={{ fontWeight: "bold", color: "#9E9E9E" }}  variant="h5">
              has been sent to printer.
            </Typography>

            <Typography style={{ fontWeight: "bold", marginTop: 20, color: "#9E9E9E" }}  variant="h5">
              您漂亮的照片
            </Typography>
            <Typography style={{ fontWeight: "bold", color: "#9E9E9E" }}  variant="h5">
              已经发送去打印机了。
            </Typography>
            
            <Typography style={{ marginTop: 20, color: "#9E9E9E" }}  variant="body2">
              Please collect your beautiful photos at the counter 
            </Typography>
            <Typography style={{ color: "#9E9E9E" }}  variant="body2">
              请到柜台领取您的照片
            </Typography>

        </Grid>
        
      </Grid>
    </>
  );
}
