import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const itemData = [
  {
    img: 'assets/template/design2.jpg',
    title: 'UDS Frame Design 2',
    link: '/design2'
  },
  {
    img: 'assets/template/design1.jpg',
    title: 'UDS Frame Design 1',
    link: '/design1'
  }
  
];

export default function HomePage() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Home | Ureshii-Desu </title>
      </Helmet>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column" }} >


        <Grid style={{ width: "100%", marginTop: 30, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
          {/* <Typography align='center' style={{ fontWeight: 700, fontSize: 30 }}>
         SIB AWARD NIGHT
  </Typography> */}
            <Typography align='center' style={{ fontWeight: "bold", marginTop: 10, color: "#9E9E9E" }}  variant="h6">
              Please select your favourite photo frame
            </Typography>
            <Box style={{ marginTop: 20 }}>
              <ImageList variant="masonry" cols={matches? matchesMd ? 4: 3 : 2} gap={8}>
                {itemData.map((item) => (
                  <ImageListItem onClick={()=> navigate(item.link)} key={item.title}>
                    <img
                      srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.img}?w=248&fit=crop&auto=format`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                )) }
              </ImageList>
            </Box>

        </Grid>
        
      </Grid>
    </>
  );
}
