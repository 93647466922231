// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDgJh6jQcUPOI7oc8Tmv25JhtN_8h9rSic",
    authDomain: "ureshii-desu.firebaseapp.com",
    databaseURL: "https://ureshii-desu-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ureshii-desu",
    storageBucket: "ureshii-desu.appspot.com",
    messagingSenderId: "1085617450559",
    appId: "1:1085617450559:web:180370ff8286f97b31d28a",
    measurementId: "G-FM7XL7RD2V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


export default app;