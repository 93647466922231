import { useState, useEffect } from 'react';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography, Grid } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LoadingButton } from '@mui/lab';
// components
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

const firebaseConfig = {
  apiKey: "AIzaSyDgJh6jQcUPOI7oc8Tmv25JhtN_8h9rSic",
  authDomain: "ureshii-desu.firebaseapp.com",
  databaseURL: "https://ureshii-desu-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ureshii-desu",
  storageBucket: "ureshii-desu.appspot.com",
  messagingSenderId: "1085617450559",
  appId: "1:1085617450559:web:180370ff8286f97b31d28a",
  measurementId: "G-FM7XL7RD2V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const auth = getAuth(app);

export default function LoginForm() {
  
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {

    if (localStorage.checkbox && localStorage.email !== "") {
            
      setIsChecked(true)
      setPassword(localStorage.password)
      setEmail(localStorage.email)
  }

  

  }, [])

  const handleClick = () => {

    let passwordPass = false;
    let emailPass = false;
    
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(email && email.length > 0 && emailRegex.test(email)){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    }


    if(password.length > 7){
      setPasswordError(false)
      passwordPass = true;
    }
    else
    {
      setPasswordError(true)
    }

    if(emailPass === true && passwordPass === true){

      if (isChecked && email !== "") {
        localStorage.email = email
        localStorage.password = password
        localStorage.checkbox = isChecked
      }
      else
      {
        localStorage.email = ""
        localStorage.password = ""
        localStorage.checkbox = false
      }

      signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        navigate('/login')
        sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
      })
      .catch((error) => {
        setPasswordError(true)
        setEmailError(true)
      })
    }

    
  };

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleOnChangePassword = (e) => {
    setPassword(e.target.value);
  }

  const handleOnChangeCheckbox = (e) => {
    setIsChecked(e.target.checked);
  }

  if(loading){
    return (
      
      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" error={emailError} value={email} onChange={handleOnChangeEmail}/>

        <TextField
          name="password"
          label="Password"
          value={password}
          error={passwordError}
          type={showPassword ? 'text' : 'password'}
          onChange={handleOnChangePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormGroup>
        <FormControlLabel control={<Checkbox checked={isChecked} onChange={handleOnChangeCheckbox} />} label="Remember Me" />
        </FormGroup>
        
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
