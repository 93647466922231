import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Routes, Route, useParams } from 'react-router-dom';
import {saveAs} from "file-saver";
import CircularProgress from '@mui/material/CircularProgress';
import { initializeApp } from "firebase/app";
import { getDatabase, set, serverTimestamp, runTransaction, ref as refDB, child, get } from "firebase/database";
// ----------------------------------------------------------------------

const firebaseConfig = {
  apiKey: "AIzaSyDgJh6jQcUPOI7oc8Tmv25JhtN_8h9rSic",
  authDomain: "ureshii-desu.firebaseapp.com",
  databaseURL: "https://ureshii-desu-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ureshii-desu",
  storageBucket: "ureshii-desu.appspot.com",
  messagingSenderId: "1085617450559",
  appId: "1:1085617450559:web:180370ff8286f97b31d28a",
  measurementId: "G-FM7XL7RD2V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getDatabase(app);

export default function Index() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  const { id } = useParams();
  const [active, setActive] = React.useState(false);
  const [photoData, setPhotoData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [combineImg, setCombineImg] =  React.useState(null);

  React.useEffect(() => {
    
    let isMounted = true;
    
    const doFetch = async () => {
      
      const dbRef = refDB(db);

      get(child(dbRef, `photo/${id.toUpperCase().trim()}`)).then((snapshot) => {
        if (snapshot.exists()) {
          setPhotoData(snapshot.val())
          setActive(true)
        } 
      })

      setLoading(false);
      
    };

    doFetch() // start the async work
      .catch((err) => {
        if (!isMounted) return; // unmounted, ignore.
        // TODO: Handle errors in your component
        console.error("failed to fetch data", err);
      });


    return () => {
      isMounted = false;
    };
  }, [])

  const handleSaveImageClick = ()=>{
    saveAs(photoData.file_url, photoData.id);
   }

  if(loading){
    return (
      
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title> Soft Copy | Ureshii-Desu </title>
      </Helmet>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }} >


        <Grid style={{ width: "100%", marginTop: 30, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
          {/* <Typography align='center' style={{ fontWeight: 700, fontSize: 30, marginTop: 30 }}>
          SIB AWARD NIGHT
  </Typography> */}

            {active ?  <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 20 }}>
              <img width={300} src={photoData.file_url} alt="softCopy"/>
            </Grid> : null }
            
            {active ? <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Button variant='contained' disableElevation onClick={handleSaveImageClick} style={{ backgroundColor: "#db1d41", marginTop: 30 }}>Download Photo</Button>
            <Typography style={{ marginTop: 20, color: "#9E9E9E" }}  variant="body2">
             Thank You For Your Support
            </Typography>
            <Typography style={{ color: "#9E9E9E" }}  variant="body2">
              谢谢您的支持
            </Typography>
          </Grid> : <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography style={{ marginTop: 20, color: "#9E9E9E" }}  variant="body2">
                Please activate your beautiful photos at the counter 
              </Typography>
              <Typography style={{ color: "#9E9E9E" }}  variant="body2">
                请到柜台激活您的照片
              </Typography>
            </Grid>  }
        </Grid>
        
      </Grid>
    </>
  );
}
