import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography, Grid, Button, Badge,ButtonGroup } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import EditIcon from '@mui/icons-material/Edit';
import Dropzone from 'react-dropzone'

import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PrintIcon from '@mui/icons-material/Print';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';

// react-pintura
import { PinturaEditor } from "@pqina/react-pintura";

// pintura
import "@pqina/pintura/pintura.css";
import { getEditorDefaults, findNode } from "@pqina/pintura";

import { initializeApp } from "firebase/app";
import { getDatabase, set, serverTimestamp, runTransaction, ref as refDB, child, get } from "firebase/database";
import { getStorage, ref, uploadString, getDownloadURL  } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import mergeImages from 'merge-images';

const firebaseConfig = {
  apiKey: "AIzaSyDgJh6jQcUPOI7oc8Tmv25JhtN_8h9rSic",
  authDomain: "ureshii-desu.firebaseapp.com",
  databaseURL: "https://ureshii-desu-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ureshii-desu",
  storageBucket: "ureshii-desu.appspot.com",
  messagingSenderId: "1085617450559",
  appId: "1:1085617450559:web:180370ff8286f97b31d28a",
  measurementId: "G-FM7XL7RD2V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getDatabase(app);

// get default properties
const editorDefaults = getEditorDefaults({
  utils: ["crop", "finetune", "filter",  "sticker", "annotate", "trim", "decorate", "redact"],
  enableButtonExport: false,
  imageWriter: {
    targetSize: {
        width: 1200,
        height: 1800,
        upscale: true,
        fit: 'cover',
    },
},
});

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(8),
  height: theme.spacing(5),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
}));

const frameName = "design3";
const templateName = "template1";

// ----------------------------------------------------------------------

export default function Index() {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  const [img1, setImg1] = React.useState(null);
  const [img2, setImg2] = React.useState(null);
  const [img3, setImg3] = React.useState(null);

  const [finalImg1, setFinalImg1] = React.useState(null);
  const [finalImg2, setFinalImg2] = React.useState(null);
  const [finalImg3, setFinalImg3] = React.useState(null);

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const [openPrint, setOpenPrint] = React.useState(false);
  const [openPrintError, setOpenPrintError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [num, setNum]= React.useState(1);
  const incNum =()=>{
    if(num<100)
    {
    setNum(Number(num)+1);
    }
  };
  const decNum = () => {
     if(num>1)
     {
      setNum(num - 1);
     }
  }


  const navigate = useNavigate();

  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  const handleClosePrintError = () => {
    setOpenPrintError(false);
  };

  const handleClose = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
  };

  const saveData = (id, b64) => {

    const registerID = `UDS${String(id).padStart(5, '0')}`
    const storage = getStorage(app);
    const storageRef = ref(storage, `photo/${registerID}_${templateName}`);
    
    // 'file' comes from the Blob or File API
    uploadString(storageRef, b64, 'data_url').then((snapshot) => {

      getDownloadURL(ref(storage, snapshot.metadata.fullPath))
        .then((url) => {

          try
          {

            set(refDB(db, `/photo/${registerID}`), {
              id: registerID,
              file_url: url,
              orientation: "portrait",
              size: "2r",
              quantity: num,
              file_fullPath: snapshot.metadata.fullPath || null,
              file_contentType: snapshot.metadata.contentType || null,
              created: serverTimestamp(),
              status: "pending_print"
            }).then(()=>{
              navigate(`/order-number/${registerID}`)
            });
          }
          catch(e)
          {
            console.log(e)
            setLoading(false)
          }

        })
        .catch((error) => {
          setLoading(false)
        });
      
    }).catch(()=>{
      setLoading(false)
    })
  }

  const handleSendtoPrint = () => {
    

      setLoading(true)
      setOpenPrint(false);
  
      mergeImages([{src: finalImg1, x: 46, y: 91 }, {src: finalImg1, x: 647, y: 91 },
        {src: finalImg2, x: 46, y: 514 }, {src: finalImg2, x: 647, y: 514 },
        {src: finalImg3, x: 46, y: 935 }, {src: finalImg3, x: 647, y: 935 },
        `assets/editing/${frameName}.png`], {
        width: 1200,
        height: 1800,
        format: 'image/jpeg'
      })
        .then(b64 => {

          const dbRef = refDB(db);

          get(child(dbRef, `photo_uuid`)).then((snapshot) => {
            if (snapshot.exists()) {
              // console.log(snapshot.val());
              const _uuid = snapshot.val().uuid + 1;
              saveData(_uuid , b64)

              set(refDB(db, 'photo_uuid'), {
                uuid: _uuid
              });

            } else {
              saveData(1, b64)

              set(refDB(db, 'photo_uuid'), {
                uuid: 1
              });
            }
          }).catch((error) => {
            console.error(error);
          }); 

  
        }).catch(()=>{
          setLoading(false)
        }) 

  }

  const componentRef = React.useRef(null);

  const willRenderToolbar = (toolbar) => {
    // get the export button
    const exportButton = findNode('export', toolbar);

    // get a reference to the options
    const exportButtonOptions = exportButton[2];

    // show the label
    exportButtonOptions.hideLabel = false;

    // disable the icon
    exportButtonOptions.icon = false;

    // return the toolbar for drawing
    return toolbar;
  };

  const exportImg = () => {
    componentRef.current.editor.processImage().then((imageWriterResult) => {

      if(open1){
        const img = new Image()
        img.src = URL.createObjectURL(imageWriterResult.dest)
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const MAX_WIDTH = 507
          const MAX_HEIGHT =  407
          let width = img.width
          let height = img.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)

          setFinalImg1(canvas.toDataURL())
        }

       
      }
      else if(open2){
        const img = new Image()
        img.src = URL.createObjectURL(imageWriterResult.dest)
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const MAX_WIDTH = 507
          const MAX_HEIGHT =  407
          let width = img.width
          let height = img.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)

          setFinalImg2(canvas.toDataURL())
        }
      }
      else if(open3){
        const img = new Image()
        img.src = URL.createObjectURL(imageWriterResult.dest)
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const MAX_WIDTH = 507
          const MAX_HEIGHT =  407
          let width = img.width
          let height = img.height

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width
              width = MAX_WIDTH
            }
          } else {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)

          setFinalImg3(canvas.toDataURL())
        }
      }
      
      setOpen1(false)
      setOpen2(false)
      setOpen3(false)
    })
  }

  const renderImg = () => {

    let img = null;
    if(open1){
      img = img1;
    }
    else if(open2){
      img = img2;
    }
    else{
      img = img3;
    }

    return img
  }

  if(loading){
    return (
      
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "70vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title> Photo Editing | Ureshii-Desu </title>
      </Helmet>

      <Dialog
        open={openPrint}
        onClose={handleClosePrint}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Send to Print 发送打印照片"}
        </DialogTitle>
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to print this photo? 
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
          您确定要打印这张照片吗？
          </DialogContentText>
          <Grid style={{ marginTop: 20 }}>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
              color="error"
            >
              <Button onClick={decNum} style={{ backgroundColor: "#db1d41"}}>-</Button>
              <Button style={{ backgroundColor: "#262626"}}>{num}</Button>
              <Button onClick={incNum} style={{ backgroundColor: "#db1d41"}}>+</Button>
            </ButtonGroup>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrint}>Cancel 取消</Button>
          <Button onClick={handleSendtoPrint} autoFocus>
            Yes 是的
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPrintError}
        onClose={handleClosePrintError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Please add your photo 请添加您的照片
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrintError}>Close 关闭</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={open1 || open2 || open3}
        onClose={handleClose}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: "#151515" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Photo Editing
            </Typography>
            <Button autoFocus color="inherit" onClick={exportImg}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid style={{ width: "100%", height: "100%"}}>
          <PinturaEditor
            {...editorDefaults}
            src={renderImg()}
            image
            imageCropAspectRatio={141/116}
            
            willRenderToolbar={willRenderToolbar}
            ref={componentRef}
          />
        </Grid>
      </Dialog>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }} >

      
        <Grid style={{ width: "100%", marginTop: 30, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              
              <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: 5, marginTop: 15 }}>
                <Link underline="hover" color="inherit" href="/home">
                  Home
                </Link>
                <Typography color="text.primary">Photo Editing</Typography>
              </Breadcrumbs>
              
            <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
              
              

              <Box style={{ marginTop: 20, width: 330 }}>
                
                <Grid container>
                  <Grid item xs={6}>

                    <Grid style={{ marginLeft: 12, width: 141, display: "flex", justifyContent: "center" }} >
                      <Dropzone multiple={false} style={{ padding: 0, margin: 0}} accept={{
                        'image/jpeg': [],
                        'image/png': []
                      }} onDrop={acceptedFiles => 
                        {
                          setImg1(acceptedFiles.map(file => Object.assign(file, {
                            preview: URL.createObjectURL(file)
                          }))[0].preview)
                          setOpen1(true)
                        }}>
                      {({ getInputProps} ) => (
                      <Grid style={{ width: 141, height: 113, overflow: "hidden" }}>
                        {finalImg1 ? <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}><img src={finalImg1} alt="img1" /></Grid> 
                        : <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}/>}
                        <Button disableElevation component="label" style={{ backgroundColor: "white", color: "#151515", 
                        opacity: .8, width: 100, height: 30, position: "relative", top: 0, left: 0, marginTop: -70, marginLeft: 20 }} 
                        variant="contained" startIcon={<AddAPhotoIcon />}>
                          {finalImg1 ? "Change" : "Add" }
                          <input {...getInputProps()} />
                        </Button>
                      </Grid>
                      )}
                      </Dropzone>
                    </Grid>

                    <Grid style={{ marginLeft: 12, width: 141, display: "flex", justifyContent: "center" }} >
                      <Dropzone multiple={false} accept={{
                        'image/jpeg': [],
                        'image/png': []
                      }} onDrop={acceptedFiles => 
                        {
                          setImg2(acceptedFiles.map(file => Object.assign(file, {
                            preview: URL.createObjectURL(file)
                          }))[0].preview)
                          setOpen2(true)
                        }}>
                      {({ getInputProps} ) => (
                      <Grid style={{ width: 141, height: 113, overflow: "hidden", marginTop: 3 }}>
                        {finalImg2 ? <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}><img src={finalImg2} alt="img2" /></Grid> 
                        : <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}/>}
                        <Button disableElevation component="label" style={{ backgroundColor: "white", color: "#151515", 
                        opacity: .8, width: 100, height: 30, position: "relative", top: 0, left: 0, marginTop: -70, marginLeft: 20 }} 
                        variant="contained" startIcon={<AddAPhotoIcon />}>
                          {finalImg2 ? "Change" : "Add" }
                          <input {...getInputProps()} />
                        </Button>
                      </Grid>
                      )}
                      </Dropzone>
                    </Grid>

                    <Grid style={{ marginLeft: 12, width: 141, display: "flex", justifyContent: "center" }} >
                      <Dropzone multiple={false} accept={{
                        'image/jpeg': [],
                        'image/png': []
                      }} onDrop={acceptedFiles => 
                        {
                          setImg3(acceptedFiles.map(file => Object.assign(file, {
                            preview: URL.createObjectURL(file)
                          }))[0].preview)
                          setOpen3(true)
                        }}>
                      {({ getInputProps} ) => (
                      <Grid style={{ width: 141, height: 113, overflow: "hidden", marginTop: 3 }}>
                        {finalImg3 ? <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}><img src={finalImg3} alt="img3" /></Grid> 
                        : <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}/>}
                        <Button disableElevation component="label" style={{ backgroundColor: "white", color: "#151515", 
                        opacity: .8, width: 100, height: 30, position: "relative", top: 0, left: 0, marginTop: -70, marginLeft: 20 }} 
                        variant="contained" startIcon={<AddAPhotoIcon />}>
                          {finalImg3 ? "Change" : "Add" }
                          <input {...getInputProps()} />
                        </Button>
                      </Grid>
                      )}
                      </Dropzone>
                    </Grid>

                  </Grid>
                  <Grid item xs={6}>

                    <Grid style={{ marginLeft: 12, width: 141, display: "flex", justifyContent: "center" }} >
                      <Grid style={{ width: 141, height: 113, overflow: "hidden" }}>
                      {finalImg1 ? <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}><img src={finalImg1} alt="img1" /></Grid> 
                        : <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}/>}
                        {finalImg1 ? <Button onClick={()=> setOpen1(true)} style={{ backgroundColor: "white", color: "#151515", opacity: .8, width: 100, 
                        height: 30, position: "relative", top: 0, left: 0, marginTop: -70, marginLeft: 20 }} 
                        variant="contained" startIcon={<EditIcon />}>
                          Edit
                        </Button> : null}
                      </Grid>
                    </Grid>

                    <Grid style={{ marginLeft: 12, width: 141, display: "flex", justifyContent: "center" }} >
                      <Grid style={{ width: 141, height: 113, overflow: "hidden", marginTop: 3 }}>
                      {finalImg2 ? <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}><img src={finalImg2} alt="img2" /></Grid> 
                        : <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}/>}
                        {finalImg2 ?  <Button onClick={()=> setOpen2(true)} style={{ backgroundColor: "white", color: "#151515", opacity: .8, width: 100, 
                        height: 30, position: "relative", top: 0, left: 0, marginTop: -70, marginLeft: 20 }} 
                        variant="contained" startIcon={<EditIcon />}>
                          Edit
                        </Button> : null}
                      </Grid>
                    </Grid>

                    <Grid style={{ marginLeft: 12, width: 141, display: "flex", justifyContent: "center" }} >
                      <Grid style={{ width: 141, height: 113, overflow: "hidden", marginTop: 3 }}>
                      {finalImg3 ? <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}><img src={finalImg3} alt="img3" /></Grid> 
                        : <Grid style={{ backgroundColor: "black", width: 141, height: 113 }}/>}
                        {finalImg3 ?  <Button onClick={()=> setOpen3(true)} style={{ backgroundColor: "white", color: "#151515", opacity: .8, width: 100, 
                        height: 30, position: "relative", top: 0, left: 0, marginTop: -70, marginLeft: 20 }} 
                        variant="contained" startIcon={<EditIcon />}>
                          Edit
                        </Button> :null}
                      </Grid>
                    </Grid>


                  </Grid>
                </Grid>
                  

                  <img style={{ position: "relative", top: 0, left: 0, pointerEvents: "none", marginTop: -370 }} 
                  src={`assets/lowres-editing/${frameName}.png`} alt={frameName}/>
              </Box>
             
              
            </Grid>

        </Grid>
        
      </Grid>

      <StyledRoot>
      <Button onClick={()=> {
        
        if(finalImg1 && finalImg2 && finalImg3){
          setOpenPrint(true)
        }
        else{
          setOpenPrintError(true)
        }
      }} style={{ height: 40, width: 140, marginRight: -5, backgroundColor: "#d91e40"}}
       disableElevation variant="contained" startIcon={<PrintIcon />}>
          Print 打印
        </Button>
      </StyledRoot>
    </>
  );
}
